import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TOKEN } from '../../../shared/constants/token';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  isAuthentication: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() {
    const token = this.getAccessToken();
    if (token) {
      this.updateToken(true);
    }
  }

  updateToken(isAuth: boolean) {
    this.isAuthentication.next(isAuth);
  }

  setToken(token: string, role: string) {
    this.updateToken(true);
    localStorage.setItem(TOKEN.ACCESS_TOKEN, token);
    localStorage.setItem(TOKEN.ROLE, role);
  }

  getAccessToken(): string | null {
    return localStorage.getItem(TOKEN.ACCESS_TOKEN) || null;
  }

  getRole(): string | null {
    return localStorage.getItem(TOKEN.ROLE) || null;
  }

  removeToken() {
    this.updateToken(false);
    localStorage.removeItem(TOKEN.ACCESS_TOKEN);
    localStorage.removeItem(TOKEN.ROLE);
    localStorage.removeItem(TOKEN.USER);
  }
}
